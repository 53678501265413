var render, staticRenderFns
import script from "./ChartjsComponentDoughnutChart.vue?vue&type=script&lang=js&"
export * from "./ChartjsComponentDoughnutChart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/jasontaylor/Sites/Cancom_Secure_Portal_2024/cancom-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a82186b2')) {
      api.createRecord('a82186b2', component.options)
    } else {
      api.reload('a82186b2', component.options)
    }
    
  }
}
component.options.__file = "src/views/charts-and-maps/charts/chartjs/charts-components/ChartjsComponentDoughnutChart.vue"
export default component.exports